import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink } from "@fortawesome/free-solid-svg-icons";  
import { useParams } from 'react-router-dom';
import "./ShareBox.css";

const ShareBox = ({ question }) => {
  
  const { question: paramQuestion } = useParams();
  const [buttonText, setButtonText] = useState("Share");
  const finalQuestion = question || paramQuestion;
  const url = `https://aislidestudio.com/presentation/${finalQuestion}`;
  
  // Truncate the URL if it exceeds 40 characters
  const truncatedUrl = url.length > 50 ? `${url.substring(0, 50)}...` : url;

  const handleCopy = () => {
    navigator.clipboard.writeText(url);
    setButtonText("Copied!");
    setTimeout(() => {
      setButtonText("Share");
    }, 2000);
  };

  const handleSelectUrl = (event) => {
    const range = document.createRange();
    range.selectNodeContents(event.target);
    const selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);
  };

  return (
    <div className="share-container">
      <div className="url-box">
        <span onClick={handleSelectUrl}>{truncatedUrl}</span>
        <button className="share-button" onClick={handleCopy}>
          <FontAwesomeIcon icon={faLink} size="xs"  />
          {buttonText}
        </button>
      </div>
    </div>
  );
};

export default ShareBox;
