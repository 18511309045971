import React, { useState } from 'react';
import './Faq.css'; 
import { motion } from 'framer-motion';

const Faq = () => {
  const [activeIndex, setActiveIndex] = useState(null);
  const [hasAnimated, setHasAnimated] = useState(false);

  const handleQuestionClick = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const scrollToSection = (id) => {
    const section = document.getElementById(id);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleLinkClick = (event, id) => {
    event.preventDefault();
    scrollToSection(id);
  };

  const handleAnimation = () => {
    if (!hasAnimated) {
      setHasAnimated(true);
    }
  };

  return (
    <div className="faq-main" id="faq">
      <div className="text-faq">
        <span>Questions? We got answers!</span>
        <motion.h1
          className="heading-faq"
          initial={{ opacity: 0, y: 50 }}
          animate={hasAnimated ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
          transition={{ duration: 1.5 }}
          onViewportEnter={handleAnimation}
        >
          FREQUENTLY ASKED QUESTIONS
        </motion.h1>
      </div>

      <div className="wrapper-faq">
        {faqData.map((item, index) => (
          <motion.div
            className="container-faq"
            key={index}
            initial={{ opacity: 0, y: 200 }}
            animate={hasAnimated ? { opacity: 1, y: 0 } : { opacity: 0, y: 200 }}
            transition={{ duration: 1.5 }}
            onViewportEnter={handleAnimation}
          >
            <div
              className={`question ${activeIndex === index ? "active" : ""}`}
              onClick={() => handleQuestionClick(index)}
            >
              {item.question}
            </div>
            <div
              className="answercont"
              style={{
                maxHeight: activeIndex === index ? "500px" : "0",
                overflow: "hidden",
                transition: "max-height 0.3s ease",
              }}
            >
              <div className="answer">
                {item.answer}
                <br />
                <br />
                <a href={item.link} onClick={(e) => handleLinkClick(e, item.link)}>Learn More</a>
              </div>
            </div>
          </motion.div>
        ))}
      </div>

      <script src="https://storage.ko-fi.com/cdn/scripts/overlay-widget.js"></script>
      <script
        dangerouslySetInnerHTML={{
          __html: `
            kofiWidgetOverlay.draw('mohamedghulam', {
              'type': 'floating-chat',
              'floating-chat.donateButton.text': 'Support me',
              'floating-chat.donateButton.background-color': '#323842',
              'floating-chat.donateButton.text-color': '#fff'
            });
          `,
        }}
      />
    </div>
  );
};

const faqData = [
  {
    question: "What is AI Slide Studio?",
    answer: `Creating a presentation with AI Slide Studio is simple and fast. Just enter your topic, and within seconds our AI PPT Maker will generate slideshows. You can then fully edit and customize these slides to suit your needs. Once you’re satisfied with your presentation, click the download button to get your file.`,
    link: "home",
  },
  {
    question: "How do I create a presentation with this AI Slideshow Generator?",
    answer: `To create a presentation, simply input your topic into AI Slide Studio. This AI slide maker will generate a set of 10 slides in seconds. You can then fully edit and customize these slides, including the ability to add AI-generated images, to suit your needs. Once you're satisfied, click the download button to get your presentation. With our AI slideshow generator, creating professional ppt presentations has never been easier.`,
    link: "home",
  },
  {
    question: "Can I edit slideshows after they are downloaded?",
    answer: `Yes, slideshows are generated in PowerPoint pptx format, allowing you to edit and customize them as needed. You can modify text, images, and slide layouts with this AI Presentation Maker.`,
    link: "home",
  },
  {
    question: "Can I edit presentations and add AI-generated images on this Slides Creator directly before downloading?",
    answer: `Yes, with this PowerPoint maker, you can customize your slides online on the platform. You can change fonts, colors, designs, add AI-generated images, and even custom backgrounds. For detailed customization, you can download presentations in PowerPoint format and further edit them using any PPT editor.`,
    link: "home",
  },
  {
    question: "Is this Ai Slide Maker free to use?",
    answer: `AI Slide Studio offers a free slides creator plan that lets you create unlimited PowerPoint presentations with access to our editor. For downloading your PPT presentations, we offer a range of subscription plans. Visit our pricing page for more details.`,
    link: "pricing",
  },
  {
    question: "Which formats does this AI PPT Maker support?",
    answer: `AI Slide Studio generates presentations in PowerPoint (PPTX) format. You can download and integrate your slides with Google Slides or Microsoft PowerPoint.`,
    link: "home",
  },
];

export default Faq;
