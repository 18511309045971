import React, { useState, useEffect } from 'react';
import './About.css';
import imgabout from './../../images/ai-slides-generator-about.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'; // Using checkmark icon
import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet';

function About() {
    const [hasAnimated, setHasAnimated] = useState(false);

    const handleAnimation = () => {
        if (!hasAnimated) {
            setHasAnimated(true);
        }
    };

    const featureIcons = [
        { icon: faCheckCircle, text: 'Create PowerPoint Presentations 100x faster.' },
        { icon: faCheckCircle, text: 'Save time and energy with AI PPT Maker.' },
        { icon: faCheckCircle, text: 'Customize your AI Presentation with different fonts, colors, backgrounds, and AI-generated images.' },
        { icon: faCheckCircle, text: 'Easily integrate with PowerPoint and Google Slides.' }
    ];

    return (
        <div className="about" id="about">
             <Helmet>
                <title>AI Slide Studio: AI PowerPoint Maker & AI PPT Maker</title>
                <meta name="description" content="AI Slide Studio is an AI Presentation Maker, also known as AI Powerpoint Maker or AI PPT Maker that creates beautiful presentations in seconds"/>
            </Helmet>
            <div className="section feature" aria-labelledby="feature-label">
                <div className="container-about">
                    <figure className="feature-banner">
                        <motion.img
                            src={imgabout}
                            width={800}
                            height={531}
                            loading="lazy"
                            alt="AI presentation maker, AI slideshow maker, and AI PPT generator with features like AI image generation, customizable backgrounds, font styles, and color themes for professional and engaging presentations."
                            className="w-100"
                            initial={{ opacity: 1, x: -200 }}
                            animate={hasAnimated ? { opacity: 1, x: 0 } : { opacity: 0, x: -200 }}
                            transition={{ duration: 1.5 }}
                            onViewportEnter={handleAnimation}
                        />
                    </figure>
                    <div className="feature-content">
                        <motion.h2
                            className="section-subtitle"
                            id="feature-label"
                            initial={{ opacity: 1, x: 200 }}
                            animate={hasAnimated ? { opacity: 1, x: 0 } : { opacity: 0, x: 200 }}
                            transition={{ duration: 1.5 }}
                            onViewportEnter={handleAnimation}
                        >
                            Why You Should Create PPTs with AI Slide Studio?
                        </motion.h2>
                        <motion.h1
                            className="section-title-about"
                            initial={{ opacity: 1, x: 200 }}
                            animate={hasAnimated ? { opacity: 1, x: 0 } : { opacity: 0, x: 200 }}
                            transition={{ duration: 1.5 }}
                            onViewportEnter={handleAnimation}
                        >
                            "AI Slideshow Maker: Create and Download Slides in Seconds"
                        </motion.h1>
                        <motion.p
                            className="section-text"
                            initial={{ opacity: 1, x: 200 }}
                            animate={hasAnimated ? { opacity: 1, x: 0 } : { opacity: 0, x: 200 }}
                            transition={{ duration: 1.5 }}
                            onViewportEnter={handleAnimation}
                        >
                            AI Slide Studio is an AI Presentation Maker that helps you make slideshows fast. Just enter your topic, and it creates PowerPoint Presentations for you in seconds, with text and pictures included. You can fully customize these slides with different fonts, colors, and even AI-generated images to fit your needs.
                        </motion.p>
                        <ul className="feature-list">
                            {featureIcons.map((item, index) => (
                                <motion.li
                                    key={index}
                                    initial={{ opacity: 1, x: 200 }}
                                    animate={hasAnimated ? { opacity: 1, x: 0 } : { opacity: 0, x: 200 }}
                                    transition={{ duration: 1.5 }}
                                    onViewportEnter={handleAnimation}
                                >
                                    <div className="feature-card">
                                        <div className="card-icon">
                                            <FontAwesomeIcon
                                                icon={item.icon}
                                                aria-hidden="true"
                                                style={{
                                                    color: '#56289f', // You can update this color if needed
                                                }}
                                            />
                                        </div>
                                        <span className="span">
                                            <strong>{item.text}</strong>
                                        </span>
                                    </div>
                                </motion.li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default About;
