import React from 'react';
import './Footer.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXTwitter } from '@fortawesome/free-brands-svg-icons';
import Glitch from '../Glitch-Text/Glitch';
import { useNavigate } from 'react-router-dom';


function Footer({ onButtonClick }) {
  const navigate = useNavigate();
  const scrollToSection = (id) => {
    const section = document.getElementById(id);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    } else {
      if (id === "home") {
       
        navigate("/");
        window.scrollTo({ top: 0, behavior: 'smooth' });
      } else {
        navigate(`/${id}`);
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }
    }
  };

  const handleButtonClick = () => {
    const section = document.getElementById('home');
  
  if (section) {
    section.scrollIntoView({ behavior: 'smooth' });
  } else {
    navigate("/");
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
    
  };

  const currentYear = new Date().getFullYear();

  return (
    <div className="footer-f">
      <div className="view grid-list">
        <div className="footer-brand">
          <div className="company-name">
            <Glitch size="20px" />
          </div>
          <div className="company-content">
            <p className="company">
              &copy; {currentYear} AISlideStudio <br /> All rights reserved.
            </p>
            <ul className="social-list">
             <li>
                <a href="https://x.com/aislidestudio_" className="social-link">
                  <FontAwesomeIcon icon={faXTwitter} />
                </a>
              </li>
              <li>
              <a href="https://twitter.com/aislidestudio_" className='twitter-id'>Follow @aislidestudio_</a>

              </li>
              
            </ul>
          </div>
        </div>
        <ul className="footer-list">
          <li>
            <p className="h4 footer-list-title">Get in Touch</p>
          </li>
          <li>
            <address className="footer-text">
            Istanbul, Turkey
            </address>
          </li>
          <li>
            <a href="mailto:aislidestudio@gmail.com" className="footer-link">aislidestudio@gmail.com</a>
          </li>
        </ul>
        <ul className="footer-list">
          <li>
            <p className="h4 footer-list-title">Learn More</p>
          </li>
          <li className='footer-link' onClick={() => scrollToSection('home')}>Home</li>
          <li className='footer-link' onClick={() => scrollToSection('about')}>About</li>
          <li className='footer-link' onClick={() => scrollToSection('pricing')}>Pricing</li>
          <li className='footer-link' onClick={() => scrollToSection('faq')}>FAQs</li>
          <li className='footer-link' onClick={() => scrollToSection('contact')}>Contact</li>
          <li>
  <a  className='footer-link'href="/terms-and-conditions">Terms and Conditions</a>

</li>
<li>
  <a  className='footer-link'href="/privacy-policy">Privacy Policy</a>

</li>
        </ul>
        <div className="butn">
          <button onClick={handleButtonClick} className="button">Try For Free</button>
        </div>
      </div>
     
    </div>
  );
}

export default Footer;
