import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import './Hero.css';
import watermark from './../../images/watermark.jpg';
import Loading from './../../components/Loading-Animation/Loading';
import ColorSchemesSlides from './../../components/ColorSchemes/ColorSchemesSlides/ColorSchemesSlides'
import img1 from './../../images/ai-ppt-maker.webp';
import useAutoHover from './../useAutoHover';
import pptxgen from 'pptxgenjs';
import { motion } from 'framer-motion';
import ColorSchemesText from './../../components/ColorSchemes/ColorSchemesText/ColorSchemesText';
import FontPicker from '../FontPicker/FontPicker';
import Alert from '../Alert/Alert';
import { FaEdit } from 'react-icons/fa';
import ColorSchemesLine from '../ColorSchemes/ColorSchemesText/ColorSchemesLine';

import image4 from './../../images/checkmark.png';
import image5 from './../../images/star.png';
import image6 from './../../images/rocket.png';
import image1 from './../../images/shield.png';
import image2 from './../../images/star-white.png';
import image3 from './../../images/rocket-w.png';


import ShareBox from '../ShareBox/ShareBox';
import ImageSelector from '../ImageSelector/ImageSelector';
import BuyPageModal from '../BuyPageModal/BuyPageModal';




function Hero() {

  
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [conclusion, setConclusion] = useState("THANK YOU");
    const [subconclusion, setSubconclusion] = useState("FOR YOUR ATTENTION");
  
    const handleConclusionChange = (e) => {
      setConclusion(e.target.textContent); // Update the conclusion state when text changes
    };
  
    const handleSubconclusionChange = (e) => {
      setSubconclusion(e.target.textContent); // Update the subconclusion state when text changes
    };
  
    const handleTextChange = (index, field, value) => {
      const updatedSlides = [...slides];
      updatedSlides[index][field] = value;
      setSlides(updatedSlides); // Update the slides state
    };
    const handleNameChange = (e) => {
      setName(e.target.textContent); 
    };

  const handleDownloadClick = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const [photo, setPhoto] = useState(); 
  const [name, setName] = useState(); 
  const [slides, setSlides] = useState([]); 
  const images = document.querySelectorAll('.images'); 
  const loadingRef = useRef(null);
  const [question, setQuestion] = useState('');
  const [answer, setAnswer] = useState('');
  const [uploadedImage, setUploadedImage] = useState(null);
  const [selectedColor, setSelectedColor] = useState('#000000'); 
  const [selectedLine, setSelectedLine] = useState('#FFD700');
  const [ selectedTitleColor,  setSelectedTitleColor] = useState('#FFFFFF');

  const bulletImages = selectedColor === '#56289f' || selectedColor === '#000000' || selectedColor === '#003366'
  ? [image1, image2, image3]
  : [image4, image5, image6];
    const [productId, setProductId] = useState(null);
    const [productName, setProductName] = useState();
  const [imageLoading, setImageLoading] = useState({}); 
  const [font1, setFont1] = useState('Arial Black');
  const [font2, setFont2] = useState('Verdana');
  const [font3, setFont3] = useState('Georgia');
    const [showAlert, setShowAlert] = useState([]);
       const [isPromptBoxVisible, setIsPromptBoxVisible] = useState(false);
    const [currentSlideIndex, setCurrentSlideIndex] = useState(null);
    const [imagePrompt, setImagePrompt] = useState("");
      const [isLoading, setIsLoading] = useState(false);
        const slidesRef = useRef(null);
        const agendaTextColor = selectedLine === '#000000' 
  ? (selectedTitleColor === '#000000' ? 'white' : selectedTitleColor) 
  : selectedLine === '#FFFFFF' 
  ? (selectedTitleColor === '#FFFFFF' ? 'black' : selectedTitleColor) 
  : selectedTitleColor;
        const handleImageClick = (index) => {
          setCurrentSlideIndex(index);
          setIsPromptBoxVisible(true);
        };
  const alerts = [
    { type: 'info', message: `ChatGPT for Presentations - AI Slide Studio is a free AI Presentation Maker that helps you create presentation slides in seconds. Whether you need AI slides, an AI slideshow generator, or a slides creator,  we have all you need.`},
    { 
      type: 'success', 
      message: 'Your presentation has been successfully created! You can now customize and download it.' 
  }
  ];
  useEffect(() => {
    if (answer === 'loading...' && loadingRef.current) {
      loadingRef.current.scrollIntoView({ behavior: 'smooth' });
      window.scrollBy(0, 720);
    }
  }, [answer]);
  
    
    useEffect(() => {
      if (isPromptBoxVisible) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = 'auto';
      }
    }, [isPromptBoxVisible]);
  
  

    useAutoHover(images);

  async function generate() {

    setSelectedColor('#000000');
    setSelectedLine('#FFD700');
    setSelectedTitleColor('#FFFFFF');
    setFont1('Arial Black');
    setFont2('Verdana');
    setFont3('Georgia');
    setShowAlert([]);
    if (!question.trim()) {
      setShowAlert([{ type: 'error', message: 'Error generating slides. Please ensure your input is correct and not empty.' }]);
     
 }
 
    setAnswer('loading...');
   
    try {
      const response = await axios.post('https://aislidestudio.onrender.com/generate-slides', {
        question: question
      });
    
      
    let { slides, presentationName, verticalPhoto} = response.data;

    // Remove "Title: " from all slide titles
    slides = slides.map(slide => ({
      ...slide,
      title: slide.title.replace(/^Title:\s*/, ''), // Remove "Title: " from the beginning of the title
    }));
  setSlides(slides); 
  setAnswer('Slides loaded');
  setName(presentationName)
  setPhoto(verticalPhoto)

    if (slides.length === 0) {
      setShowAlert([{ type: 'error', message: 'Error generating slides. Please ensure your input is correct and not empty.' }]);
    } 
    
  } catch (error) {
    console.error('Error fetching data from server:');
    setAnswer('Error fetching data. Please try again.');
    setShowAlert([{ type: 'error', message: 'Error generating slides. Please ensure your input is correct and not empty.' }]);
  }
}

const handleGenerateImage = async () => {
  if (!imagePrompt) return; 

  const updatedSlides = [...slides];
  updatedSlides[currentSlideIndex].image = ''; 
  setSlides(updatedSlides);

  setImageLoading(prevState => ({
    ...prevState,
    [currentSlideIndex]: true
  }));
  setIsPromptBoxVisible(false); 
  setImagePrompt(""); 

  try {
    // Call the backend to generate an image (now returns base64)
    const response = await fetch('https://aislidestudio.onrender.com/generate-image', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ imagePrompt }),
    });

    const data = await response.json();

    if (data.base64Image) {
      const updatedSlides = [...slides];
      updatedSlides[currentSlideIndex].image = data.base64Image;
   
      setSlides(updatedSlides);
      

    } else {
      console.error('Image generation failed:');
    }
  } catch (error) {
    console.error('Error generating image:');
  }
};


  function generatePPTX(slides) {
  const pptx = new pptxgen();
  // Create a new slide
const introSlide = pptx.addSlide();

// Set background color or image
if (uploadedImage) {
  introSlide.background = { data: uploadedImage };
} else {
  introSlide.background = { fill: selectedColor };
}

// Add image
introSlide.addImage({
  path: photo,
  x: 0,
  y: 0,
  w: 4,
  h: 5.625,
});

// Add name text
introSlide.addText(name, {
  x: 4.5,
  y: 2.7,
  w: 5,
  fontSize: 28,
  color: selectedTitleColor,
  align: 'center',
  fontFace: font1,
});

const agendaSlide = pptx.addSlide();
if (uploadedImage) {
  agendaSlide.background = { data: uploadedImage };
} else {
  agendaSlide.background = { fill: selectedColor };
}

const titles = slides.map((slide, index) => `${index + 1}. ${slide.title}`);


// Divide titles into two columns
const midIndex = Math.ceil(titles.length / 2);
const leftColumn = titles.slice(0, midIndex);
const rightColumn = titles.slice(midIndex);

// Left column
agendaSlide.addText(leftColumn.join("\n\n"), {
  x: 0.5,
  y: 1.7,
  w: 5,
  h: 3,
  fontSize: 15,
  fontFace: font2,
  color: selectedTitleColor,
 
});

// Right column
agendaSlide.addText(rightColumn.join("\n\n"), {
  x: 5.2,
  y: 1.7,
  w: 5,
  h: 3,
  fontSize: 15,
  fontFace: font2,
  color: selectedTitleColor,
 
});
// Add elegant header banner
agendaSlide.addShape(pptx.ShapeType.rect, {
  x: 0,
  y: 0,
  w: '100%',
  h: 1.2,
  fill: { color: selectedLine },
  opacity: 0.2
});

// Add main "AGENDA" title
agendaSlide.addText("AGENDA", {
  x: 1,
  y: 0.35,
  w: 8,
  h: 0.8,
  fontSize: 44,
  bold: true,
  fontFace: font1,
  color: selectedTitleColor,
 
});

// Add decorative line under AGENDA
agendaSlide.addShape(pptx.ShapeType.rect, {
  x: 1.1,
  y: 1.3,
  w: 2.5,
  h: 0.06,
  fill: { color: selectedLine }
});

// Add decorative bottom line
agendaSlide.addShape(pptx.ShapeType.rect, {
  x: 0,
  y: 5.2,
  w: '100%',
  h: 0.06,
  fill: { color: selectedLine },
  opacity: 0.2
});

  // Add content slides
  slides.forEach((slide) => {
    const { title, paragraph, image, bulletPoints, imageAttribution } = slide;

    const slideInstance = pptx.addSlide();

    // Background setup
    slideInstance.background = uploadedImage
      ? { data: uploadedImage }
      : { fill: selectedColor };

    // Add title
    slideInstance.addText(title, {
      x: 1,
      y: 0.67,
      fontSize: 25,
      bold: true,
      fontFace: font1,
      align: "center",
      color: selectedTitleColor,
    });

    // Add image
    slideInstance.addImage({
      path: image,
      x: 0.5,
      y: 1.2,
      w: 5,
      h: 3,
    });

  
    // if (imageAttribution) {
    //   slideInstance.addText(`Photo by ${imageAttribution} on Unsplash`, {
    //     x: 0.5,
    //     y: 4.35,
    //     fontSize: 10,
    //     fontFace: font2,
        
    //     color: selectedTitleColor,
    //   });
    // }

    // Add paragraph
    slideInstance.addText(paragraph, {
      x: 0.4,
      y: 5,
      w: "90%",
      fontSize: 11,
      fontFace: font3,
 
      color: selectedTitleColor,
    });

    // Add a horizontal line for design
    slideInstance.addShape(pptx.ShapeType.rect, {
      x: 0.4,
      y: 4.5,
      w: 9.2,
      h: 0.08,
      
      fill: { color: selectedLine },
    });

    // Add bullet points
    bulletPoints.forEach((point, i) => {
      const gap = 0.4;
      if (bulletImages[i]) {
        slideInstance.addImage({
          path: bulletImages[i],
          x: 5.82,
          y: 1.70 + i * (0.5 + gap),
          w: 0.208,
          h: 0.208,
        });
      }
      slideInstance.addText(`${point}`, {
        x: 5.7,
        y: 1.8 + i * (0.5 + gap),
        w: 4.5,
        fontSize: 15,
        fontFace: font2,
        italic: true,
        color: selectedTitleColor,
        margin: 30,
      });
    });
  });
  
  const thankYouSlide = pptx.addSlide();
  if (uploadedImage) {
    thankYouSlide.background = { data: uploadedImage };
  } else {
    thankYouSlide.background = { fill: selectedColor };
  }


  // Add main "THANK YOU" text
  thankYouSlide.addText(conclusion, {
    x: 1.9,
    y: 2.5,
    w: 9,
    fontSize: 72,
    bold: true,
    fontFace: font1,
   
    color: selectedTitleColor
  });

  // Add decorative line
  thankYouSlide.addShape(pptx.ShapeType.rect, {
    x: 3.3,
    y: 3.7,
    w: 3.3,
    h: 0.06,
    fill: { color: selectedLine }
  });

  // Add subtitle text
  thankYouSlide.addText(subconclusion, {
    x: 3.1,
    y: 3.4,
    w: 9,
    fontSize: 28,
    fontFace: font2,
    
    color: selectedTitleColor,
    italic: true
  });
  // Add decorative bottom line
  thankYouSlide.addShape(pptx.ShapeType.rect, {
  x: 0,
  y: 5.2,
  w: '100%',
  h: 0.06,
  fill: { color: selectedLine },

});

// Function to sanitize filename
function sanitizeFilename(filename) {
  // Replace invalid characters with underscores
  return filename
    .replace(/[/\\?%*:|"<>:]/g, '_') // Replace invalid Windows filename characters
    .replace(/\s+/g, '_')            // Replace spaces with underscores
    .trim();                         // Remove leading/trailing spaces
}

setIsLoading(true);
pptx.write('blob').then((pptxBlob) => {
  const timestamp = Date.now();
  const pptxName = sanitizeFilename(name); // Sanitize the filename
  const uniqueId = `${pptxName}_${timestamp}`; 
  setProductId(uniqueId);
  setProductName(`${name} Presentation`);

  const formData = new FormData();
  formData.append('file', pptxBlob, `${uniqueId}.pptx`);
  formData.append('productId', uniqueId);

  fetch('https://payment-a6cq.onrender.com/upload-pptx', {
    method: 'POST',
    body: formData
  })
  .then(response => response.json())
  .then(data => {
    console.log('PPTX file uploaded successfully:');
 
    
    handleDownloadClick();
    setIsLoading(false);  
  })
  .catch(error => {
    console.error('Error uploading PPTX:');
    setIsLoading(false);
  });
});

  }


const titles = (slides || []).map((slide, index) => `${index + 1}. ${slide.title || 'Untitled'}`);
const midIndex = Math.ceil(titles.length / 2);
const leftColumn = titles.slice(0, midIndex);
const rightColumn = titles.slice(midIndex);
  
  return (
    <div>
      <div className="hero" id="home">
        <motion.div
          initial={{ opacity: 0, y: -10 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 2 }}
          className="headline-container"
        >
          <h1 className='headline-text'>
              AI Slideshow Maker: Presentations in Seconds
           </h1>
        </motion.div>

        <div className="hero-content">
          <div className="hero-img-container">
            <motion.img
              initial={{ opacity: 0, x: -200 }}
              whileInView={{ opacity: 1, x: 0 }}
              transition={{ duration: 2 }}
              src={img1}
              alt="AI slide maker and slideshow generator for creating beautiful PPT presentations in seconds"
              className="hero-img"
            />
          </div>
          <div className="hero-form">
           
            <div className="container-s">
              <div className="search-container">
              <input
               className="input"
                 type="text"
                 value={question}
                   onChange={(e) => {
                  setQuestion(e.target.value);
                      }}
                placeholder="Enter your topic here"
                  />

                <svg viewBox="0 0 24 24" className="search__icon">
                  <g>
                    <path d="M21.53 20.47l-3.66-3.66C19.195 15.24 20 13.214 20 11c0-4.97-4.03-9-9-9s-9 4.03-9 9 4.03 9 9 9c2.215 0 4.24-.804 5.808-2.13l3.66 3.66c.147.146.34.22.53.22s.385-.073.53-.22c.295-.293.295-.767.002-1.06zM3.5 11c0-4.135 3.365-7.5 7.5-7.5s7.5 3.365 7.5 7.5-3.365 7.5-7.5 7.5-7.5-3.365-7.5-7.5z"></path>
                  </g>
                </svg>
              </div>
            </div>
            <button className="btn3" onClick={generate}>
              <span className="btn-text">Create Presentation!</span>
              <svg
                fill="#ffffff"
                width="40px"
                height="40px"
                viewBox="0 0 16 16"
                xmlns="http://www.w3.org/2000/svg"
                className="icon"
              >
                <path d="M12.52.55l-5,5h0L.55,12.51l3,3,12-12Zm-4,6,4-4,1,1-4,4.05ZM2.77,3.18A3.85,3.85,0,0,1,5.32,5.73h0A3.85,3.85,0,0,1,7.87,3.18h0A3.82,3.82,0,0,1,5.32.64h0A3.82,3.82,0,0,1,2.77,3.18ZM8.5,2.55h0A2,2,0,0,1,9.78,1.27h0A1.92,1.92,0,0,1,8.5,0h0A1.88,1.88,0,0,1,7.23,1.27h0A1.92,1.92,0,0,1,8.5,2.55Zm-6.36,0h0A1.92,1.92,0,0,1,3.41,1.27h0A1.88,1.88,0,0,1,2.14,0h0A1.92,1.92,0,0,1,.86,1.27h0A2,2,0,0,1,2.14,2.55ZM14.73,6.22h0a1.94,1.94,0,0,1-1.28,1.27h0a1.94,1.94,0,0,1,1.28,1.27h0A1.9,1.9,0,0,1,16,7.49h0A1.9,1.9,0,0,1,14.73,6.22Z" />
              </svg>
            </button>
          </div>
          <div className="hero-right-text">
            {alerts.slice(0, 1).map((alert, index) => (
              <Alert
                key={index}
                type={alert.type}
                message={alert.message}
              />
            ))}
          </div>
        </div>
      </div>

      {answer === 'loading...' ? (
        <div className="loading-animation" ref={loadingRef}>
          <Loading/>
        </div>
      ) : (
        answer && (
          <>
            <div className="outside notranslate">
              <div className='alert-container'>
                {showAlert.length > 0 ? (
                  showAlert.map((alert, index) => (
                    <Alert
                      key={index}
                      type={alert.type}
                      message={alert.message}
                    />
                  ))
                ) : (
                  alerts.slice(1, 2).map((alert, index) => (
                    <Alert
                      key={index}
                      type={alert.type}
                      message={alert.message}
                    />
                  ))
                )}
              </div>

              {showAlert.length === 0 && (
                <>
                  <div className="customize">
                    <ColorSchemesSlides selectedColor={selectedColor} setSelectedColor={setSelectedColor} />
                    <ColorSchemesLine selectedColorLine={selectedLine} setselectedColorLine={setSelectedLine} />
                    <ColorSchemesText selectedColorText={selectedTitleColor} setSelectedColorText={setSelectedTitleColor} />
                  </div>
                  <div className="customize-2">
                    <FontPicker selectedFont={font1} setSelectedFont={setFont1} labelText="Select Title Font:" />
                    <FontPicker selectedFont={font2} setSelectedFont={setFont2} labelText="Select BulletPoints Font:" labelFontSize="19px" />
                    <FontPicker selectedFont={font3} setSelectedFont={setFont3} labelText="Select Paragraph Font:" labelFontSize="19px" />
                  </div>
                  <div className='customize-3'>
                <ImageSelector uploadedImage={uploadedImage} setUploadedImage={setUploadedImage} />
                  </div>
                </>
              )}

              {showAlert.length === 0 && (
                <>
                
                  <div className="slides-container" ref={slidesRef}>
    <div
      className="slide"
      style={{
        backgroundColor: uploadedImage ? "transparent" : selectedColor,
        backgroundImage: uploadedImage ? `url(${uploadedImage})` : "none",
        backgroundSize: "cover",
        backgroundPosition: "center",
        color: selectedTitleColor,
      }}
    > 
     <div className="note-container">
      <div className="note-text">
        <strong>Click any text on the slide to edit</strong>
      </div>
      <FaEdit className="icon" />
    </div>
    <img
          src={watermark}
        alt="AI slideshow maker watermark"

          className="watermark"
          style={{
            position: "absolute",
            top: "15px",
            right: "15px",
            width: "110px", // Adjust width as needed
            height: "auto", // Maintain aspect ratio
          borderRadius: "5px",
            zIndex: 999, // Ensure it's above other content
          }}
        />
         <img
    src={photo} className='firstphoto'
    alt="Please wait while the image is loading...Or there might be no image available at the moment."
    
  />
  
  <span
      contentEditable
      suppressContentEditableWarning
      onBlur={handleNameChange} // Save the changes when editing is done
      style={{
        fontSize: "28pt", // 2.5 inches converted to pixels
        color: selectedTitleColor, // Use the selected title color
        textAlign: "center", // Center the text horizontally
        position: "absolute", // Position relative to the parent
        top: "2.1in", // Center vertically
        left: "4.5in", // Center horizontally
        width: "5in",
        fontFamily: font1,
      }}
    >
      {name}
    </span>
    
</div></div>
                  <div className="slides-container" ref={slidesRef}>
    <div
      className="slide"
      style={{
        backgroundColor: uploadedImage ? "transparent" : selectedColor,
        backgroundImage: uploadedImage ? `url(${uploadedImage})` : "none",
        backgroundSize: "cover",
        backgroundPosition: "center",
        color: selectedTitleColor,
      }}
    >
           <div 
        className="agenda-banner"
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '1.2in',
          backgroundColor: selectedLine,
         
        }}
      />

      {/* AGENDA title */}
      <span 
        className="agenda-title"
        style={{
          position: 'absolute',
          left: '1in',
          top: '0.35in',
          fontSize: '44pt',
          width:'8in',
          height:'0.8in',
          fontWeight: 'bold',
          fontFamily: font1,
          color: agendaTextColor
        }}
      >
        AGENDA
      </span>

      {/* Decorative line under AGENDA */}
      <div 
        style={{
          position: 'absolute',
          left: '1.1in',
          top: '1.3in',
          width: '2.5in',
          height: '0.06in',
          
          backgroundColor: selectedLine
        }}
      />

      {/* Left column */}
      <p 
        className="agenda-column-1"
        style={{
          position: 'absolute',
          left: '0.5in',
         top: '1.7in',
         width: '4.5in',
         height:'3in',
         fontSize:'15pt',
          fontFamily: font2,
          whiteSpace: 'pre-line'
        }}
      >
        {leftColumn.join('\n\n')}
      </p>

      {/* Right column */}
      <p 
        className="agenda-column-2"
        style={{
          position: 'absolute',
          left: '5.2in',
          top: '1.7in',
          width: '4.5in',
          height:'3in',
          fontSize:'15pt',
          fontFamily: font2,
          whiteSpace: 'pre-line'
        }}
      >
        {rightColumn.join('\n\n')}
      </p>

      {/* Bottom decorative line */}
      <div
        style={{
          position: 'absolute',
          top: '5.2in',
          left: '0in',
          width: '100%',
          height: '0.06in',
          backgroundColor: selectedLine,
         
        }}
      />
    
    </div>
  </div>
                  {slides.map((slide, index) => (
                    <div key={index} className="slides-container" ref={slidesRef}>
                                       <div
  className="slide"
  style={{
    backgroundColor: uploadedImage ? "transparent" : selectedColor,
    backgroundImage: uploadedImage ? `url(${uploadedImage})` : "none",
    backgroundSize: "cover",
    backgroundPosition: "center",
    color: selectedTitleColor,
  }}
>
  
<img
          src={watermark}
        alt="AI slideshow maker watermark"

          className="watermark"
          style={{
            position: "absolute",
            top: "15px",
            right: "15px",
            width: "110px", // Adjust width as needed
            height: "auto", // Maintain aspect ratio
            borderRadius: "5px",
            zIndex: 999, // Ensure it's above other content
          }}
        />
                     <div>
  {/* Editable Title */}
  <span
    className="slide-title"
    contentEditable
    suppressContentEditableWarning
    onBlur={(e) => handleTextChange(index, 'title', e.target.textContent)} // Handle title change
    style={{ fontFamily: font1 }}
  >
    {slide.title}
  </span>
</div>

<div>
  {/* Editable Paragraph */}
  <p
    className="paragraph"
    contentEditable
    suppressContentEditableWarning
    onBlur={(e) => handleTextChange(index, 'paragraph', e.target.textContent)} // Handle paragraph change
    style={{ fontFamily: font3 }}
  >
    {slide.paragraph}
  </p>
</div>

                     
                      
                       
                        <img
  src={slide.image}
  alt="Please wait while the image is loading...Or there might be no image available at the moment."
  className="images"
  onClick={() => handleImageClick(index)}
  onLoad={() => setImageLoading(prevState => ({
    ...prevState,
    [index]: false
  }))}
  style={{
    cursor: 'pointer',
    transition: 'all 0.3s ease',
    
  }}
/>

{imageLoading[index] && (
  <div className="loading-spinner">
    <span className="magic-icon">✨</span>
    <span className="loading-text">Generating...</span>
  </div>
)}


<div className="image-overlay">
  <span className="magic-icon">✨</span>
  <span className="image-label"> Click to Generate AI Photos</span>
</div>



                      
<ul className="bullet-points" style={{ fontFamily: font2 }}>
  {slide.bulletPoints.map((point, i) => (
    <li key={i} style={{ display: 'flex', alignItems: 'center' }}>
      <img
        src={bulletImages[i] || bulletImages[0]}
        alt={`Bullet point ${i}`}
        style={{ marginRight: '8px', width: '20px', height: '20px' }}
      />
      <span
        contentEditable
        suppressContentEditableWarning
        onBlur={(e) => handleTextChange(index, 'bulletPoint', i, e.target.textContent)} // Handle bullet point change
      >
        {point}
      </span>
    </li>
  ))}
</ul>

                        <div className="horizontal-line" style={{ backgroundColor: selectedLine }}></div>
                      </div>
                    </div>
                  ))}
                 <div className="slides-container" ref={slidesRef}>
  <div
    className="slide"
    style={{
      
      backgroundColor: uploadedImage ? "transparent" : selectedColor,
      backgroundImage: uploadedImage ? `url(${uploadedImage})` : "none",
      backgroundSize: "cover",
      backgroundPosition: "center",
      color: selectedTitleColor,
    
      
    }}
  >
    <img
          src={watermark}
        alt="AI slideshow maker watermark"

          className="watermark"
          style={{
            position: "absolute",
            top: "15px",
            right: "15px",
            width: "110px", // Adjust width as needed
            height: "auto", // Maintain aspect ratio
            borderRadius: "5px",
            zIndex: 999, // Ensure it's above other content
          }}
        />
    <span
        className="thankyou-title"
        contentEditable
        suppressContentEditableWarning
        onBlur={handleConclusionChange} // Save changes when editing is done
        style={{
          position: 'absolute',
          left: '1.6in',
          top: '1.5in',
          fontSize: '72pt',
          fontWeight: 'bold',
          fontFamily: font1,
          width: '9in',
        }}
      >
        {conclusion}
      </span>
    <div
      className="thankyou-subtitle-line"
      style={{
        position: 'absolute',
        width: '3.3in',
        height: '0.06in',
        backgroundColor: selectedLine,
     left:'3.3in',
     top:'3.7in'
      }}
    />
     <p
        className="thankyou-subtitle"
        contentEditable
        suppressContentEditableWarning
        onBlur={handleSubconclusionChange} // Save changes when editing is done
        style={{
          fontSize: '28pt',
          fontFamily: font2,
          fontStyle: 'italic',
          left: '3.2in',
          top: '2.8in',
          width: '9in',
          position: 'absolute',
        }}
      >
        {subconclusion}
      </p>
    <div
      className="bottom-line-thankyou"
      style={{
        width: '100%',
        height: '0.06in',
        backgroundColor: selectedLine,
        position: 'absolute',
        top: '5.2in',
        left: '0',
      }}
    />
  </div>
</div>

                </>
                
              )}
               <BuyPageModal isOpen={isModalOpen} onClose={closeModal}  productId={productId}
        productName={productName} />
{isPromptBoxVisible && (
  <div className="prompt-box">
    <div className="prompt-content">
      <h3>Enter Image Prompt</h3>
      <textarea
        type="text"
        value={imagePrompt}
        onChange={(e) => setImagePrompt(e.target.value)}
        placeholder="Describe the image you want..."
      />
      <button onClick={handleGenerateImage}>Generate Image</button>
      <button onClick={() => setIsPromptBoxVisible(false)}>Cancel</button>
    </div>
  </div>
)} 
    <div className='notice-box'>
      
               <Alert
    type="info"
    message={
      <ul>
        <li>Install the required fonts on your system for proper display. Otherwise, default fonts may be used.</li>
        <li>The final presentation may differ slightly from the preview in terms of layout.</li>
        <li>AI-generated content may not be entirely accurate and could need further refinement.</li>
      </ul>
    }
  />
              </div>

              {showAlert.length === 0 && (
                
               <div className='share-info'>
                <div className="downloadbtn">
                    <div className='share-div'>
                    <button className="btn3" onClick={(e) => { generatePPTX(slides); }} disabled={isLoading}>

                    {isLoading ? (
            <div className="spinner"></div>  
          ) : (
                  <svg width="64px" height="64px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g id="File / Download_Package"> <path id="Vector" d="M4 8V16.8C4 17.9201 4 18.4798 4.21799 18.9076C4.40973 19.2839 4.71547 19.5905 5.0918 19.7822C5.5192 20 6.07899 20 7.19691 20H16.8031C17.921 20 18.48 20 18.9074 19.7822C19.2837 19.5905 19.5905 19.2839 19.7822 18.9076C20 18.4802 20 17.921 20 16.8031V8M4 8H20M4 8L5.36518 5.61089C5.7002 5.0246 5.86768 4.73151 6.10325 4.51807C6.31184 4.32907 6.55859 4.18605 6.82617 4.09871C7.12861 4 7.46623 4 8.14258 4H15.8571C16.5334 4 16.8723 4 17.1747 4.09871C17.4423 4.18605 17.6879 4.32907 17.8965 4.51807C18.1322 4.73168 18.3002 5.02507 18.6357 5.6123L20 8M12 11V17M12 17L15 15M12 17L9 15" stroke="#ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> </g> </g></svg>
                )}
          {isLoading ? 'Processing...' : 'DOWNLOAD NOW'}
                  </button>

                 
                  </div>
                  <div className='share-div'>
                  <ShareBox question={question} />

               
                </div>
                </div>
                </div>

                
              )}
           
            </div>
            
          </>
        )
      )}
    </div>
  );
}

export default Hero;
