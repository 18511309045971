import { useEffect } from 'react';

const useAutoHover = (images) => {
  useEffect(() => {
    let autoHoverTimeouts = [];
    let autoHoverIntervals = [];

    const triggerAutoHover = (image) => {
      image.classList.add('auto-hover');
      setTimeout(() => {
        image.classList.remove('auto-hover');
      }, 2000);
    };

    const restartAllTimers = () => {
      autoHoverTimeouts.forEach(timeout => clearTimeout(timeout));
      autoHoverIntervals.forEach(interval => clearInterval(interval));

      autoHoverTimeouts = [];
      autoHoverIntervals = [];

      images.forEach(image => {
        let autoHoverTimeout;
        let autoHoverInterval;

        autoHoverTimeout = setTimeout(() => {
          if (!image.matches(':hover')) {
            triggerAutoHover(image);
          }
        }, 8000);

        autoHoverInterval = setInterval(() => {
          if (!image.matches(':hover')) {
            triggerAutoHover(image);
          }
        }, 8000);

        autoHoverTimeouts.push(autoHoverTimeout);
        autoHoverIntervals.push(autoHoverInterval);
      });
    };

    images.forEach(image => {
      let autoHoverTimeout;
      let autoHoverInterval;

      image.addEventListener('mouseleave', () => {
        restartAllTimers();
      });

      image.addEventListener('mouseenter', () => {
        clearTimeout(autoHoverTimeout);
        clearInterval(autoHoverInterval);
        image.classList.remove('auto-hover');
      });

      autoHoverTimeouts.push(autoHoverTimeout);
      autoHoverIntervals.push(autoHoverInterval);
    });

    restartAllTimers();

    return () => {
      autoHoverTimeouts.forEach(timeout => clearTimeout(timeout));
      autoHoverIntervals.forEach(interval => clearInterval(interval));
    };
  }, [images]);
};

export default useAutoHover;
