import React from 'react';

const styles = {
  container: {
    maxWidth: '1000px',
    margin: '0 auto',
    padding: '80px 20px',
    fontFamily: 'system-ui, -apple-system, sans-serif',
    color: '#333',
  },
  header: {
    marginBottom: '40px',
  },
  title: {
    fontSize: '32px',
    fontWeight: 'bold',
    marginBottom: '8px',
  },
  date: {
    fontSize: '14px',
    color: '#666',
  },
  section: {
    marginBottom: '32px',
  },
  sectionTitle: {
    fontSize: '24px',
    fontWeight: '600',
    marginBottom: '16px',
  },
  subsectionTitle: {
    fontSize: '18px',
    fontWeight: '500',
    marginBottom: '12px',
  },
  paragraph: {
    lineHeight: '1.6',
    marginBottom: '16px',
  },
  list: {
    paddingLeft: '24px',
    marginBottom: '16px',
  },
  listItem: {
    marginBottom: '8px',
    lineHeight: '1.6',
  },
  footer: {
    marginTop: '40px',
    paddingTop: '20px',
    borderTop: '1px solid #eee',
    fontSize: '14px',
    color: '#666',
  },
};

const PrivacyPolicy = () => {
  return (
    <div style={styles.container}>
      <div style={styles.header}>
        <h1 style={styles.title}>Privacy Policy for AI Slide Studio</h1>
        <p style={styles.date}>Effective Date: January 18, 2025</p>
      </div>

      <section style={styles.section}>
        <p style={styles.paragraph}>
          At AI Slide Studio, we prioritize the protection of your personal information and are committed to maintaining transparency about how we handle your data. This Privacy Policy outlines our practices for collecting, using, and safeguarding your information when you use our AI-powered presentation generation service.
        </p>
      </section>

      <section style={styles.section}>
        <h2 style={styles.sectionTitle}>1. Information We Collect</h2>
        
        <h3 style={styles.subsectionTitle}>1.1 Essential Information</h3>
        <ul style={styles.list}>
          <li style={styles.listItem}>
            <strong>Email Address:</strong> Required for delivering your AI-generated presentations and order-related communications.
          </li>
          <li style={styles.listItem}>
            <strong>Payment Information:</strong> Processed securely through our payment provider. We never store your payment details.
          </li>
          <li style={styles.listItem}>
            <strong>Presentation Content:</strong> Any text, prompts, or specifications you provide for generating your presentations.
          </li>
        </ul>
        
        <h3 style={styles.subsectionTitle}>1.2 Technical Information</h3>
        <ul style={styles.list}>
          <li style={styles.listItem}>Browser type and version</li>
          <li style={styles.listItem}>Device information</li>
          <li style={styles.listItem}>IP address</li>
          <li style={styles.listItem}>Usage patterns and interactions with our service</li>
        </ul>
      </section>

      <section style={styles.section}>
        <h2 style={styles.sectionTitle}>2. How We Use Your Information</h2>
        
        <h3 style={styles.subsectionTitle}>2.1 Service Delivery</h3>
        <ul style={styles.list}>
          <li style={styles.listItem}>Generate and deliver your AI-powered presentations</li>
          <li style={styles.listItem}>Process your payments securely</li>
          <li style={styles.listItem}>Provide customer support and respond to your inquiries</li>
          <li style={styles.listItem}>Send essential service notifications and updates</li>
        </ul>

        <h3 style={styles.subsectionTitle}>2.2 Service Improvement</h3>
        <ul style={styles.list}>
          <li style={styles.listItem}>Enhance our AI presentation generation capabilities</li>
          <li style={styles.listItem}>Improve user experience and interface</li>
          <li style={styles.listItem}>Analyze service performance and usage patterns</li>
          <li style={styles.listItem}>Debug technical issues and maintain service quality</li>
        </ul>
      </section>

      <section style={styles.section}>
        <h2 style={styles.sectionTitle}>3. Our AI Technology and Third-Party APIs</h2>
        <p style={styles.paragraph}>
          AI Slide Studio integrates with several third-party services and APIs to create your presentations:
        </p>
        <ul style={styles.list}>
          <li style={styles.listItem}>
            <strong>Advanced Language Model API:</strong> We integrate with a leading AI language model API to generate professional, engaging, and contextually relevant content for your slides
          </li>
          <li style={styles.listItem}>
            <strong>AI Image Generation API:</strong> We use an advanced AI image generation API to create custom, unique visuals tailored to your presentation needs
          </li>
          <li style={styles.listItem}>
            <strong>Stock Image API:</strong> We connect to a professional stock photo API to access a vast library of high-quality images when needed
          </li>
          <li style={styles.listItem}>
            <strong>Payment Processing API:</strong> We use a secure, industry-standard payment processing API to handle all financial transactions
          </li>
        </ul>
        <p style={styles.paragraph}>
          These third-party APIs process your presentation requirements and data in accordance with their respective privacy policies and our data protection standards. While they help us deliver our service, they do not store your personal information beyond what is necessary for their specific functions.
        </p>
      </section>

      
      <section style={styles.section}>
  <h2 style={styles.sectionTitle}>5. Uploading and Using Images</h2>
  <p style={styles.paragraph}>
    When you upload an image to use as a background or part of your presentation, the following applies:
  </p>

  <h3 style={styles.subsectionTitle}>Image Usage</h3>
  <p style={styles.paragraph}>
    The image you upload is used exclusively for generating and displaying your presentations on AI Slide Studio. We do not use these images for any other purpose, and we do not share your uploaded images with third parties, except as necessary to provide the service.
  </p>

  <h3 style={styles.subsectionTitle}>Data Retention</h3>
  <p style={styles.paragraph}>
    Your uploaded images will be retained temporarily for the duration of your session. Once your presentation is generated, the image will no longer be stored on our servers. However, the generated presentations, including any images you uploaded, may be saved in our system for the purpose of delivering the presentation to you after purchase or for internal purposes, such as storing your presentation for future access, re-editing, or download.
  </p>

  <h3 style={styles.subsectionTitle}>User-Provided Content Responsibility</h3>
  <p style={styles.paragraph}>
    By uploading an image, you confirm that you have the necessary rights or permission to use the image. You also agree that the image does not infringe on any third-party rights.
  </p>
</section>


      <section style={styles.section}>
        <h2 style={styles.sectionTitle}>6. Contact Information</h2>
        <p style={styles.paragraph}>For privacy-related questions or concerns, please contact us at:</p>
        <ul style={styles.list}>
          <li style={styles.listItem}>Email: aislidestudio@gmail.com</li>
          <li style={styles.listItem}>Website: www.aislidestudio.com</li>
        </ul>
      </section>

      <footer style={styles.footer}>
        <p>
          By using AI Slide Studio, you agree to this Privacy Policy. We reserve the right to update this policy as needed.
        </p>
      </footer>
    </div>
  );
};

export default PrivacyPolicy;