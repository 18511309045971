import React from "react";
import "./BuyPageModal.css";
import badge from "./../../images/Lemon-Squeezy-Payments-Badge-Black.png";

const BuyPageModal = ({ isOpen, onClose, productName, productId }) => {
  if (!isOpen) return null;

  const handleBuyNow = async () => {
    try {
      // Making the fetch request to your server, which will handle the API request
      const response = await fetch("https://payment-a6cq.onrender.com/create-checkout-session", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          productName: productName,   // Dynamically set product name
          productId: productId,       // Include product ID
        }),
      });
  
      // Check for success
      if (!response.ok) {
        throw new Error("Failed to create checkout session");
      }
  
      // Parse the response from the server
      const data = await response.json();
      const checkoutUrl = data.checkoutUrl;
  
      // Open the checkout page in a new window
   
      window.open(checkoutUrl, '_blank');

    } catch (error) {
      console.error("Error creating checkout session");
    }
  };
  
  

  return (
    <div className="buy-modal-overlay" onClick={onClose}>
      <div
        className="buy-modal-content"
        onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside the modal
      >
        <button
          className="buy-modal-close"
          onClick={onClose}
          aria-label="Close modal"
        >
          &times;
        </button>
        <div className="buy-modal-preview">
          <h2 className="buy-modal-title">
            Save Time and Create Beautiful Presentations!
          </h2>
        </div>
        <ul className="buy-modal-features">
          <li>Delivered in PowerPoint Format</li>
          <li>PDF Version Included</li>
          <li>High-quality, editable formats.</li>
          <li>Quick Email Delivery</li>
          <li>No Watermark</li>
        </ul>
        <div className="buy-modal-pricing">
          <div className="buy-modal-option buy-modal-single-download">
            <div className="buy-modal-stars">⭐⭐⭐⭐⭐</div>
            <h3>One-Time Payment</h3>
            <p className="buy-modal-description">
              One-time payment for a single presentation. No subscription
              required!.
            </p>
            <p className="buy-modal-price">Only $2.50 Per Presentation!</p>
            <button
              className="buy-modal-button"
              onClick={handleBuyNow}
            >
              Buy Now
            </button>
          </div>
        </div>
        <div className="buy-modal-footer">
          <p className="buy-modal-contact">
            Questions? <span className="buy-modal-arrow">➤</span>{" "}
            <a href="mailto:aislidestudio@gmail.com">aislidestudio@gmail.com</a>
          </p>
          <div className="buy-modal-powered">
            <img
              src={badge}
              alt="Lemon-Squeezy-Payments-Badge-Black"
              className="buy-modal-badge"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BuyPageModal;
