import React from 'react';
import './ImageSelector.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudUploadAlt } from '@fortawesome/free-solid-svg-icons';

function ImageSelector({ uploadedImage, setUploadedImage }) {

  const handleRemoveImage = () => {
    setUploadedImage(null);
  };

  return (
    <div className="main-upload">
      <div className="container-upload">
        <label className="text-upload" htmlFor="file-upload">
          <FontAwesomeIcon icon={faCloudUploadAlt} style={{ marginRight: '8px' }} />
          {uploadedImage ? 'Image Selected' : 'Custom Image'}
        </label>
        <input
          id="file-upload"
          type="file"
          accept="image/*"
          className="upload-picker"
          onChange={(e) => {
            const file = e.target.files[0];
            if (file) {
              const reader = new FileReader();
              reader.onload = (event) => setUploadedImage(event.target.result);
              reader.readAsDataURL(file);
            }
          }}
        />
        <button
          className="custom-upload-button"
          onClick={() => document.getElementById('file-upload').click()}
        >
          {uploadedImage ? 'Change Image' : 'Upload Background'}
        </button>

        {uploadedImage && (
          <div className="uploaded-image-preview">
            <img src={uploadedImage} alt="Uploaded" />
            <button onClick={handleRemoveImage}>X</button>
          </div>
        )}
      </div>
    </div>
  );
}

export default ImageSelector;
