import React from 'react';
import './IntroSection.css';
import { useParams } from 'react-router-dom';

const IntroSection = () => {
  const { question } = useParams();
  
  return (
    <>
      <section className="intro-section">
        <div className="container">
          <div className="text-content">
            <a href="https://www.aislidestudio.com" target="_blank" rel="noopener noreferrer" className="tagline">
              Make Your Own Presentations with AI
            </a>
          </div>
        </div>
      </section>

      <h1 className="presentation-topic">{question} Presentation</h1>
    </>
  );
};

export default IntroSection;
