import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Hero from './components/Hero/Hero';
import Footer from './components/Footer/Footer';
import About from './components/About/About';
import Pricing from './components/Pricing/Pricing';
import Faq from './components/Faq/Faq';
import Header from './components/Header/Header';
import { Analytics } from '@vercel/analytics/react';
import './App.css';
import Presentations from './components/Presentations/Presentations';
import Contact from './components/Contact/Contact';
import NotFound from './components/NotFound/NotFound'; 
import TermsAndConditions from './components/TermsAndConditions/TermsAndConditions';
import PrivacyPolicy from './components/PrivacyPolicy/PrivacyPolicy';

function App() {
  return (
    <Router>
      <div className="app">
        <Routes>
          <Route
            path="/"
            element={
              <>
                <Header />
                <Hero />
                <About />
                <Pricing />
                <Faq />
                <Footer />
              </>
            }
          />

          <Route
            path="/presentation/:question"
            element={
              <>
                <Header />
                <Presentations />
                <Footer />
              </>
            }
          />

          <Route
            path="/about"
            element={
              <>
                <Header />
                <About />
                <Footer />
              </>
            }
          />

          <Route
            path="/pricing"
            element={
              <>
                <Header />
                <Pricing />
                <Footer />
              </>
            }
          />

          <Route
            path="/faq"
            element={
              <>
                <Header />
                <Faq />
                <Footer />
              </>
            }
          />

          <Route
            path="/contact"
            element={
              <>
                <Header />
                <Contact />
                <Footer />
              </>
            }
          />
          <Route
            path="/terms-and-conditions"
            element={
              <>
                <Header />
                <TermsAndConditions />
                <Footer />
              </>
            }
          />

          <Route
            path="/privacy-policy"
            element={
              <>
                <Header />
                <PrivacyPolicy />
                <Footer />
              </>
            }
          />


        
          <Route path="*" element={<NotFound />} />
        </Routes>
        <Analytics />
      </div>
    </Router>
  );
}

export default App;
