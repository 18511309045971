
import React from 'react';
import './loading.css';


const Loading= () => {
  return (
<div class="typewriter">
  <div class="slide-l"><i></i></div>
  <div class="paper"></div>
  <div class="keyboard"></div>
</div>

  );
};

export default Loading;
