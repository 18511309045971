import React from 'react';

const styles = {
  container: {
    maxWidth: '1000px',
    margin: '0 auto',
    padding: '80px 20px',
    fontFamily: 'system-ui, -apple-system, sans-serif',
    color: '#333',
  },
  header: {
    marginBottom: '40px',
  },
  title: {
    fontSize: '32px',
    fontWeight: 'bold',
    marginBottom: '8px',
  },
  date: {
    fontSize: '14px',
    color: '#666',
    marginBottom: '24px',
  },
  section: {
    marginBottom: '32px',
  },
  sectionTitle: {
    fontSize: '24px',
    fontWeight: '600',
    marginBottom: '16px',
  },
  paragraph: {
    lineHeight: '1.6',
    marginBottom: '16px',
  },
  list: {
    paddingLeft: '24px',
    marginBottom: '16px',
  },
  listItem: {
    marginBottom: '12px',
    lineHeight: '1.6',
  },
  footer: {
    marginTop: '40px',
    paddingTop: '20px',
    borderTop: '1px solid #eee',
    fontSize: '14px',
    color: '#666',
  },
};

const TermsAndConditions = () => {
  return (
    <div style={styles.container}>
      <div style={styles.header}>
        <h1 style={styles.title}>Terms and Conditions for AI Slide Studio</h1>
        <p style={styles.date}>Last Updated: January 18, 2025</p>
        <p style={styles.paragraph}>
          Welcome to AI Slide Studio! By accessing and using our services, you agree to comply with and be bound by the following terms and conditions. Please read them carefully.
        </p>
      </div>

      <section style={styles.section}>
        <h2 style={styles.sectionTitle}>1. Introduction</h2>
        <p style={styles.paragraph}>
          AI Slide Studio provides AI-powered presentation generation services. Our service allows users to generate PowerPoint (PPTX) and PDF presentations based on input data and content. By using our website or services, you agree to these Terms and Conditions, Privacy Policy, and any additional guidelines provided.
        </p>
      </section>

      <section style={styles.section}>
        <h2 style={styles.sectionTitle}>2. Services Provided</h2>
        <p style={styles.paragraph}>
          Presentation Generation: AI Slide Studio allows you to generate presentations using AI technology, including text and images, which are delivered in PPTX and PDF formats.
          Payment: Payments for presentations are processed through our payment gateway, Lemon Squeezy.
          No Account Requirement: There is no requirement for user sign-up or account creation. Presentations are generated upon payment and delivered to the email provided by the user.
        </p>
      </section>

      <section style={styles.section}>
        <h2 style={styles.sectionTitle}>3. Payment and Refunds</h2>
        <p style={styles.paragraph}>Payment Processing: Payments are handled through Lemon Squeezy and subject to their terms and conditions.</p>
        <p style={styles.paragraph}>Refund Policy:</p>
        <p style={styles.paragraph}>
          No refunds are available once the presentation has been generated and sent to the user's email.
          If the email is not received due to technical issues on our end (e.g., server failure), we will either resend the presentation or issue a refund.
          Requests for refunds must be made within 3 days of the original transaction date.
        </p>
      </section>

      <section style={styles.section}>
        <h2 style={styles.sectionTitle}>4. Delivery of Files</h2>
        <p style={styles.paragraph}>
          File Delivery: Your generated presentation will be sent to the email address provided after the payment is confirmed.
          Email Delivery Issues: If you do not receive the email containing your presentation, please contact us at aislidestudio@gmail.com within 3 days of the order. We will either resend the presentation or investigate the issue.
        </p>
      </section>

      <section style={styles.section}>
        <h2 style={styles.sectionTitle}>5. Use of AI-Generated Content</h2>
        <p style={styles.paragraph}>
          AI Content Disclaimer: Our platform uses third-party AI services to generate images and text. These generated images and text may not be fully accurate and may contain errors.
          The AI-generated images are not real, and any resemblance to actual persons or events is purely coincidental.
          We are not responsible for any inaccuracies or issues arising from the use of AI-generated content.
          User Responsibility: You are responsible for ensuring that AI-generated content complies with applicable laws, including copyright laws, and does not infringe upon any third-party rights.
          Content Review: It is your responsibility to review and verify the accuracy of the generated content before using it.
        </p>
      </section>

      <section style={styles.section}>
  <h2 style={styles.sectionTitle}>6. Third-Party APIs</h2>
  <p style={styles.paragraph}>
    Use of Third-Party Services: Our platform relies on third-party APIs to generate content, including text and images. 
    No Liability: We do not take responsibility for the quality, accuracy, or availability of these third-party services. 
    By using our platform, you agree to comply with the terms of use of any third-party services integrated into our platform.
  </p>
</section>


      <section style={styles.section}>
        <h2 style={styles.sectionTitle}>7. Intellectual Property</h2>
        <p style={styles.paragraph}>
          Ownership: You retain ownership of any content you provide to the platform (such as input data for presentations). However, by using our service, you grant us a license to use your data solely for the purpose of generating your presentation.
          AI-Generated presentation is owned by you upon delivery after payment, but you are responsible for ensuring that their use complies with applicable laws.
        </p>
      </section>

      <section style={styles.section}>
        <h2 style={styles.sectionTitle}>8. Limitations of Liability</h2>
        <p style={styles.paragraph}>
          No Guarantee of Accuracy: While we strive for high-quality output, we do not guarantee that the AI-generated content will be error-free or meet your specific requirements.
          Liability Limit: We are not responsible for any direct, indirect, or consequential damages resulting from the use of our service or the AI-generated content.
        </p>
      </section>
      <section style={styles.section}>
  <h2 style={styles.sectionTitle}>9. User-Provided Content</h2>

  <h3 style={styles.sectionTitle}>Ownership and Rights</h3>
  <p style={styles.paragraph}>Users must confirm they possess all necessary rights or permissions to use the images they upload.</p>
  <p style={styles.paragraph}>Users are responsible for ensuring their images do not infringe on any third-party intellectual property or other rights.</p>

  <h3 style={styles.sectionTitle}>License Grant</h3>
  <p style={styles.paragraph}>By uploading images, users grant <strong>AI Slide Studio</strong> a non-exclusive, worldwide, royalty-free license to use, display, and distribute the images strictly for the purpose of generating the requested presentations.</p>
  <p style={styles.paragraph}>This license does not transfer ownership of the images to AI Slide Studio. Users retain full ownership of their content.</p>

  <h3 style={styles.sectionTitle}>Content Restrictions</h3>
  <p style={styles.paragraph}>Users agree not to upload any of the following types of content:</p>
  <ul style={styles.list}>
    <li style={styles.listItem}>Images that infringe on intellectual property rights (e.g., copyrighted or trademarked materials).</li>
    <li style={styles.listItem}>Content that is offensive, defamatory, obscene, hateful, or otherwise unlawful.</li>
    <li style={styles.listItem}>Images containing personal data or information about others without their explicit consent.</li>
    <li style={styles.listItem}>Any material that violates applicable laws or promotes harmful activities.</li>
  </ul>

  <h3 style={styles.sectionTitle}>Liability and Indemnification</h3>
  <p style={styles.paragraph}><strong>User Responsibility</strong></p>
  <p style={styles.paragraph}>Users bear full responsibility for the images they upload and any legal consequences that arise from their use. AI Slide Studio is not liable for disputes or legal actions related to user-uploaded content.</p>

  <p style={styles.paragraph}><strong>Indemnification</strong></p>
  <p style={styles.paragraph}>Users agree to indemnify, defend, and hold harmless AI Slide Studio, its affiliates, and its representatives from any claims, damages, losses, or expenses (including legal fees) resulting from their uploaded content.</p>

  <h3 style={styles.sectionTitle}>Content Removal</h3>
  <p style={styles.paragraph}>AI Slide Studio reserves the right to review and remove any uploaded images that violate these terms, at its sole discretion, without prior notice.</p>

  <h3 style={styles.sectionTitle}>Disclaimer</h3>
  <p style={styles.paragraph}>AI Slide Studio does not monitor or pre-approve user-provided content. By using the platform, users acknowledge and agree to these terms.</p>
</section>



      <section style={styles.section}>
        <h2 style={styles.sectionTitle}>10. Changes to Services and Terms</h2>
        <p style={styles.paragraph}>
          Modification or Discontinuation: We reserve the right to modify, suspend, or discontinue the service at any time without notice. This includes changes to the pricing, features, and availability of our platform.
          Support Email Changes: We may change the support email address or communication methods at any time without prior notice.
        </p>
      </section>

      <section style={styles.section}>
        <h2 style={styles.sectionTitle}>11. Termination</h2>
        <p style={styles.paragraph}>
          We may terminate or suspend your access to our services if we believe that you have violated these Terms and Conditions or engaged in conduct that is harmful to us or other users.
        </p>
      </section>

      <section style={styles.section}>
        <h2 style={styles.sectionTitle}>12. Miscellaneous</h2>
        <p style={styles.paragraph}>
          No Waiver: No failure or delay by us in exercising any right under these Terms and Conditions shall constitute a waiver of that right.
          Severability: If any part of these Terms and Conditions is found to be invalid, the rest of the Terms shall remain in full effect.
        </p>
      </section>

      <section style={styles.section}>
        <h2 style={styles.sectionTitle}>13. Acknowledgment</h2>
        <p style={styles.paragraph}>
          By using the AI Slide Studio services, you acknowledge that you have read, understood, and agreed to these Terms and Conditions.
        </p>
      </section>

      <section style={styles.section}>
        <h2 style={styles.sectionTitle}>Contact Information</h2>
        <p style={styles.paragraph}>
          For any questions, please contact us at aislidestudio@gmail.com
        </p>
      </section>

      
    </div>
  );
};

export default TermsAndConditions;